import { Global } from '@emotion/react'
import PropTypes from 'prop-types'
import React, { useCallback, useRef, useState } from 'react'
import ReactPlayer from 'react-player/file'
import tw, { css, styled, theme } from 'twin.macro'
import { ReactComponent as Close } from '../images/icon-close.svg'
import { ReactComponent as Play } from '../images/icon-play.svg'
import { StyleType } from '../utils/prop-types'

const PlayerPlayIcon = styled(Play)`
  ${tw`absolute transform scale-50 -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2`}
  path:first-of-type {
    transition: fill 0.3s ease-in-out;
    fill: ${(props) => (props.hover ? theme`colors.accent.500` : theme`colors.transparent`)};
  }
  path {
    transition: fill 0.3s ease-in-out;
    fill: ${(props) => (props.hover ? theme`colors.primary.500` : theme`colors.accent.500`)};
  }
  &.is-hovered {
    path:first-of-type {
      transition: fill 0.3s ease-in-out;
      fill: ${theme`colors.accent.500`};
    }
    path {
      transition: fill 0.3s ease-in-out;
      fill: ${theme`colors.accent.500`};
    }
  }
`

const VideoPlayer = ({ streamingUrl, thumbnailUrl, style, ...props }) => {
  const playerRef = useRef(null)
  const [hover, setHover] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onPlay = useCallback(() => {
    setIsPlaying(true)
  }, [playerRef])

  const onPause = useCallback(() => {
    setIsPlaying(false)
  }, [playerRef])

  const onThumbnailClick = useCallback(() => {
    setIsModalOpen(true)
    setIsPlaying(true)
  }, [])

  const closeVideoModal = (e) => {
    e.preventDefault()
    setIsModalOpen(false)
  }

  return (
    <>
      {isModalOpen && (
        <Global
          styles={css`
            body {
              ${tw`overflow-hidden`}
            }
          `}
        />
      )}
      <div
        css={[
          css`
            ${isModalOpen ? tw`static` : tw`relative`}
            padding-top: 56.25%; /* 16:9 ratio */
            .react-player__preview {
              transition: box-shadow 0.3s ease-in-out;
              box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
              &:hover {
                box-shadow: none;
              }
            }
          `,
          style,
        ]}
      >
        {thumbnailUrl && (
          <div
            css={css`
              ${tw`absolute inset-0 cursor-pointer`}
              video {
                ${tw`object-cover`}
              }
            `}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={onThumbnailClick}
            aria-hidden="true"
          >
            <ReactPlayer
              url={thumbnailUrl}
              width="100%"
              height="100%"
              playing
              loop
              muted
              playsinline
            />
            <div
              css={[
                css`
                  ${tw`absolute top-0 left-0 w-full h-full`}
                  transition: box-shadow 0.3s ease-in-out;
                  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
                `,
                hover && tw`shadow-none`,
              ]}
            />
            <PlayerPlayIcon hover={hover ? 1 : 0} />
          </div>
        )}
        <div
          css={[
            tw`absolute bottom-0 left-0 transition-opacity duration-300 ease-in-out opacity-0 pointer-events-none top-mobile-header lg:top-desktop-header`,
            (isModalOpen || !thumbnailUrl) &&
              tw`fixed z-20 w-full h-full opacity-100 pointer-events-auto bg-primary-600`,
          ]}
        >
          {isModalOpen && (
            <div css={[tw`flex flex-wrap content-center w-10/12 h-full m-auto`]}>
              <button
                type="button"
                css={tw`absolute top-0 right-0 w-12 h-12 cursor-pointer bg-accent-500`}
                onClick={closeVideoModal}
              >
                <div
                  css={[
                    tw`flex flex-wrap content-center w-full h-full`,
                    tw`lg:(transition-transform duration-300 ease-in-out) lg:hover:(transform rotate-90)`,
                  ]}
                >
                  <Close css={tw`m-auto`} />
                </div>
              </button>
              <div css={tw`w-auto h-auto m-auto`}>
                <ReactPlayer
                  ref={playerRef}
                  url={streamingUrl}
                  width="auto"
                  height="auto"
                  playing={isPlaying}
                  onPlay={onPlay}
                  onPause={onPause}
                  controls
                  {...props}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

VideoPlayer.propTypes = {
  streamingUrl: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  style: StyleType,
  props: PropTypes.array,
}

export default VideoPlayer
